<template>
  <div id="cms-cash-counts">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">Cash Counts</h2></b-col>
        <b-col>
          <router-link class="link-color" :to="{ name: 'CmsCashCountCreate' }" @click.prevent>
            <b-button type="submit" variant="success">Add Cash Count</b-button>
          </router-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="col-sm-9 cash-count-table">
          <div v-if="loading" class="text-center">
            <b-spinner variant="secondary" label="Loading"></b-spinner>
          </div>
          <b-table
                  v-else
                  striped
                  sort-by="orderByColumn"
                  sort-desc :fields="fields"
                  :items="cashCounts"
                  style="color: #CBA661"
                  head-variant="light"
                  sticky-header="320px"
          >
            <template v-slot:cell(date)="date">
              <b class="text-info">
                <span :style="{ fontWeight: 'lighter', color: '#CBA661' }">{{ date.value }}</span>
              </b>
            </template>
            <template v-slot:cell(total)="total">
              <b class="text-info">€{{ total.value }}</b>
            </template>
            <template v-slot:cell(id)="id">
              <b-button-group size="small">
                <router-link class="link-color" :to="{ name: 'CmsCashCountShow', params: { id: id.value }}" @click.prevent>
                  <b-icon icon="search" style="margin-right: 1em; color: #CBA661"></b-icon>
                </router-link>
                <router-link class="link-color" :to="{ name: 'CmsCashCountUpdate', params: { id: id.value }}" @click.prevent>
                  <b-icon icon="pencil" style="margin-right: 1em; color: #CBA661"></b-icon>
                </router-link>
              </b-button-group>
            </template>
          </b-table>
          <b-pagination
                  v-if="!loading"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="right"
                  size="sm"
                  @change="handlePageChange"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import SideMenu from '@/components/SideMenu.vue'

  export default {
    data() {
      return {
        loading: true,
        cashCountResponse: null,
        cashCounts: [],
        currentPage: 1,
        totalRows: 1,
        perPage: 20,
        orderByColumn: 'date',
        orderBy: 'desc',
        fields: [
          {
            key: 'date',
            label: 'Date'
          },
          {
            key: 'total',
            label: 'Total'
          },
          {
            key: 'id',
            label: 'Actions',
          },
        ]
      }
    },
    components: {
      SideMenu
    },
    beforeCreate () {
      this.axios
          .get('https://batavia-backend.herokuapp.com/api/cash-count', {
            params: {
              pageNumber: 1,
              perPage: 20,
              orderByColumn: 'date',
              orderBy: 'desc'
            }
          })
          .then(response => (
              this.cashCountResponse = response.data,
              this.totalRows = this.cashCountResponse.total,
              this.perPage = this.cashCountResponse.per_page,
              this.currentPage = this.cashCountResponse.current_page,
              this.cashCounts = JSON.parse(JSON.stringify(this.cashCountResponse.data)),
              this.cashCounts.forEach(function (cashCount) {
                cashCount['total'] = cashCount['total'].toFixed(2)
              })
          ))
          .finally(() => {
            this.loading = false
          })
    },
    methods: {
      handlePageChange(value) {
        this.loading = true
        this.currentPage = value

        this.axios
            .get('https://batavia-backend.herokuapp.com/api/cash-count', {
              params: {
                pageNumber: this.currentPage,
                perPage: this.perPage,
                orderByColumn: this.orderByColumn,
                orderBy: this.orderBy
              } })
            .then(response => (
                this.cashCountResponse = response.data,
                    this.totalRows = this.cashCountResponse.total,
                    this.perPage = this.cashCountResponse.per_page,
                    this.cashCounts = JSON.parse(JSON.stringify(this.cashCountResponse.data)),
                    this.cashCounts.forEach(function (cashCount) {
                      cashCount['total'] = cashCount['total'].toFixed(2)
                    })
            ))
            .finally(() => {
              this.$router.push({ name: 'CmsCashCounts' })
              this.loading = false
            })
      }
    }
  }
</script>

<style scoped>
  .cash-count-table {
    overflow: auto;
    height: 400px;
  }
</style>
